import React from "react";
import './header.css';
import {Route} from "../../interfaces/Route";

type Props = {
    routes: Route[];
    onChangeRoute: (route: Route) => void;
}

type State = {
    currentPath: string;
}

export default class Header extends React.Component<Props, State> {

    constructor(props: Props, state: State) {
        super(props, state);

        const path = window.location.pathname.substr(1);

        this.state = {
            currentPath: path
        }
    }

    render() {
        const {routes} = this.props;
        const {currentPath} = this.state;

        return (
            <div className="header">
                <a href="#home" className="logo">ExactSwag</a>
                <div className="header-right">
                        {routes.map((route) =>
                            <a className={currentPath === route.path ? 'active' : ''} href={`#${route.name}`} onClick={() => this.changeRoute(route)}>
                                {route.name}
                            </a>
                        )}
                </div>
            </div>
        )
    }

    changeRoute(route: Route) {
        const {onChangeRoute} = this.props;

        onChangeRoute(route);
        this.setState({currentPath: route.path})
    }
}
