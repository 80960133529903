import React from 'react';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import docs from './docs';
import {Error} from "./components/error/error";
import Header from "./components/header/header";
import {Doc} from "./interfaces/Doc";
import {Route} from "./interfaces/Route";

type State = {
    doc: Doc | undefined;
    access: Route[];
}

export default class App extends React.Component<any, State> {

    constructor(props: any) {
        super(props);
        const path = window.location.pathname.substr(1);
        const doc = docs.find((doc) => doc.path === path);

        this.state = {
            doc: doc,
            access: []
        };
    }

    /**
     * Set route access and add current route to the access to be able
     * to return to the current route (first).
     */
    componentDidMount() {
        const {doc} = this.state;

        if (doc && doc.access) {
            const access: Route[] = [];
            if (typeof doc.access === 'object') {
                doc.access.map(docAccess => access.push(docAccess));
                access.unshift({
                    path: doc.path,
                    name: doc.name
                });
            } else if (doc.access === '*') {
                docs.map((doc) => access.push({name: doc.name, path: doc.path}));
            }

            this.setState({access: access});
        }
    }

    render() {
        const {doc, access} = this.state;

        if (!doc) {
            return (
                <Error code={404}/>
            );
        } else {
            return (
                <div>
                    {
                        access.length ?
                            <Header routes={access}
                                    onChangeRoute={(route) => this.changeRoute(route)}/>
                            : null
                    }
                    <SwaggerUI url={doc.swaggerUrl}/>
                </div>
            );

        }
    }

    changeRoute(route: Route) {
        const doc = docs.find((doc) => doc.path === route.path);
        this.setState({doc: doc});
    }
}

