import React from "react";

type Props = {
    code: number
}

export class Error extends React.Component<Props> {

    render() {
        const {code} = this.props;

        const primaryColor = 'rgb(65, 89, 108)';

        return (
            <div style={{
                position: 'absolute', left: '50%', top: '50%',
                transform: 'translate(-50%, -50%)',
                width: '50vh',
                maxWidth: '500px',
                height: '30vh',
                color: primaryColor
            }}>
                <span style={{
                    position: 'absolute',
                    borderRadius: 10,
                    transform: 'translate(40%, -50%)',
                    fontSize: 20,
                    background: "white",
                    paddingRight: 5,
                    paddingLeft: 5,
                    fontWeight: 'bold',
                }}>
                    ERROR
                </span>
                <div style={{
                    textAlign: 'center',
                    borderWidth: 5,
                    borderColor: (code.toString()[0] === '5' ? 'red' : primaryColor),
                    borderStyle: 'solid',
                    borderRadius: 10,
                    padding: 10,
                    width: '100%',
                    height: '100%',
                }}>
                    <span style={{
                        textAlign: "center",
                        fontSize: '15em'
                    }}>
                      {code}
                    </span>
                </div>

            </div>
        );
    }
}
