import {Doc} from "./interfaces/Doc";

const DOCS: Doc[] = [
    {
        name: 'Api',
        path: 'api',
        swaggerUrl: '/documentations/api.json'
    },
    {
        name: 'Vidal',
        path: 'vidal',
        swaggerUrl: '/documentations/vidal.yaml'
    },
    {
        name: 'Excalibur Microservice',
        path: 'excalibur',
        swaggerUrl: '/documentations/excalibur_microservice.yaml'
    },
    {
        name: 'Papi',
        path: 'papi',
        swaggerUrl: '/documentations/papi.json'
    }
];

export default DOCS;
